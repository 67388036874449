.app {
  text-align: center;
}

.app-header {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1vw;
  width: 40vw;
  height: 40vh;
}
